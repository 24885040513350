<template>
    <v-container class="mt-0 pt-0 mb-0 pb-0">
        <v-row class="ml-2 mr-2 pa-0">
            <v-col class="ma-0 pa-0 d-flex justify-start" align-self="center">
                <span>{{ $t("Qty") }}</span>
            </v-col>
            <v-col class="ma-0 pa-0 d-flex justify-end" align-self="center">
                <span width="auto">
                    {{ new Intl.NumberFormat().format(qtySelected) }}
                </span>
            </v-col>
        </v-row>
        <v-row class="ml-2 mr-2 pa-0">
            <v-col 
                class="ma-0 pa-0 d-flex justify-start" 
                align-self="center"
            >
                <span>{{ $t("SubTotal") }}</span>
            </v-col>
            <v-col 
                class="ma-0 pa-0 d-flex justify-end" 
                align-self="center"
            >
                <span width="auto">
                    {{ Intl.NumberFormat('de-DE', { style: 'currency', currency: selectedCurrency }).format(subTotal) }}
                </span>
            </v-col>
        </v-row>
        <v-row class="ml-2 mr-2 pa-0">
            <v-col 
                class="ma-0 pa-0 d-flex justify-start" 
                align-self="center"
            >
                <span>{{ $t("Discount") }}</span>
            </v-col>
            <v-col 
                class="ma-0 pa-0 d-flex justify-end" 
                align-self="center" 
                v-if="position == 'checkout' && discountAmount == 0"
            >
                <span 
                    width="auto" 
                    class="btn-have-voucher" 
                    @click="clickHaveVoucher"
                >
                    {{ $t("IHaveAVoucher") }}
                </span>
            </v-col>
            <v-col 
                class="ma-0 pa-0 d-flex justify-end flex-column" 
                align-self="center" 
                v-else
            >
                <span 
                    width="auto" 
                    class="text-right"
                >
                    {{ Intl.NumberFormat('de-DE', { style: 'currency', currency: selectedCurrency }).format(discountAmount) }}
                </span>
                <span 
                    width="auto" 
                    class="btn-have-voucher text-right" 
                    @click="clickHaveVoucher"
                    v-if="position == 'checkout'"
                >
                    {{ '(' + discountCode + ')' }}
                </span>
            </v-col>
        </v-row>
        <v-row class="ml-2 mr-2 mb-2 pa-0">
            <v-col 
                class="ma-0 pa-0 d-flex justify-start" 
                align-self="center"
            >
                <h4>{{ $t("Total") }}</h4>
            </v-col>
            <v-col 
                class="ma-0 pa-0 d-flex justify-end" 
                align-self="center"
            >
                <h4 width="auto">
                    {{ Intl.NumberFormat('de-DE', { style: 'currency', currency: selectedCurrency }).format(totalAmount) }}
                </h4>
            </v-col>
        </v-row>
        <v-row>
            <v-col
                class="mt-0 pt-0"
                cols="12"
                align-self="center"
            >
                <ac-button 
                    :active="$partnerACL.isShowButton('checkout')"
                    :name="$t('CheckOut')" 
                    :on-click-handler="checkOut"
                    :is-disabled="subTotal == 0"
                    width="100%"
                />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import store from "@/store";
import AcButton from "@/components/AcButton.vue";

export default {
    name: "FooterCheckout",
    components: {
        AcButton
    },
    props: {
        position: {
            type: String,
            required: true
        }
    },
    computed: {
        selectedCurrency() {
            return this.$store.state.purchaseticket.currency ? this.$store.state.purchaseticket.currency.code : ""
        },
        items() {
            return store.state.purchaseticket.cart
        },
        qtySelected() {
            return store.state.purchaseticket.countCart
        },
        subTotal() {
            let itemTemp = this.items.filter((el) => {
                return el.selected == true;
            });
            let _subtotal = itemTemp.map(
                (itm) => this.convertPriceByCurrency(itm.priceTotal, itm.currencyCode)
            );
            let total;
            if (_subtotal.length > 1) {
                total = _subtotal.reduce((a, b) => a + b);
            } else {
                total = _subtotal;
            }
            return total;
        },
        discountData() {
            return store.state.purchaseticket.discount.data
        },
        discountAmount() {
            return store.state.purchaseticket.discount.value
        },
        discountCode() {
            return store.state.purchaseticket.discount.name
        },
        totalAmount() {
            return this.subTotal - this.discountAmount
        },
    },
    methods: {
        clickHaveVoucher() {
            const checkoutButton = this.$partnerACL.getMetaButton('voucher');
            if (checkoutButton) {
                if (checkoutButton.menu_type == "wsc_menu") {
                    this.$router.replace({ path: "/voucher" });
                } else {
                    window.location = checkoutButton.redirect_path;
                }
            } else {
                this.$router.push({ name: "Voucher" });
            }
        },
        convertPriceByCurrency(price, currency) {
            const conversionRates = this.$store.state.purchaseticket.currency ? this.$store.state.purchaseticket.currency.conversionRates : null;
            const priceResult = price * conversionRates[currency];
            return priceResult;
        },
        checkOut() {
            const checkoutButton = this.$partnerACL.getMetaButton('checkout');
            if (checkoutButton) {
                if (checkoutButton.menu_type == "wsc_menu") {
                    this.$router.replace({ path: checkoutButton.redirect_path });
                } else {
                    window.location = checkoutButton.redirect_path;
                }
            } else {
                this.$router.push({ name: "Checkout" });
            }
        },
        async reloadValidation(param) {
            const payload = {
                ...param,
                customer_id: store.state.auth.userData.customerID,
                partner_id: store.state.auth.userData.partnerID,
                amount_transaction: this.subTotal[0] || this.subTotal
            }
            await this.$store.dispatch("discount/validation", payload)
                .then(res => {
                    if (res.status) {
                        const val = {
                            name: payload.code,
                            value: res.data.discount_price,
                            type: payload.type,
                            data: payload
                        }
                        store.commit("purchaseticket/SET_DISCOUNT", val);
                    }
                })
                .catch(err => {
                    console.log(err)
                    const val = {
                        value: 0
                    }
                    store.commit("purchaseticket/SET_DISCOUNT", val);
                })
        }
    },
    mounted() {
        if (this.discountAmount > 0) {
            const payload = this.discountData
            this.reloadValidation(payload)
        }
    },
}
</script>

<style scoped>
.btn-have-voucher {
    font-weight: bold;
    color: rgba(242, 153, 0, 1)
}

.text-right {
    text-align: right;
}
</style>