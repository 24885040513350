<template>
  <v-row align="center" justify="center">
    <v-col cols="12" class="content pa-0 ma-0">
      <v-card elevation="0" class="card-content">
        <v-row align="center" class="ma-0 pa-0">
          <v-col cols="1" class="d-flex justify-center">
                <v-checkbox
                  color="primary"
                  v-model="select"
                  default="true"
                ></v-checkbox>
          </v-col>
          <v-col v-if="fareType == 3" class="pt-0 pb-0" cols="11">
            <v-row align="center" justify="center" class="ma-0 pa-0 pt-1">
              <v-col class="d-flex justify-center pa-0 ma-0 pt-1" cols="12">
                <div class="rule-1">
                  <!-- {{ routeInformation.valid_days }} <span style="text-transform: lowercase;">{{$t("Days")}} , {{ routeInformation.max_trip }} {{$t("Trips")}} </span> -->
                  <span><b>{{ $t("BusTicket") }} </b></span>
                  <v-icon
                    v-on:click="clickRouteInformation()"
                    class="pl-1"
                    x-small
                    >mdi-information-outline
                  </v-icon>
                </div>
                <!-- <v-icon
                  v-on:click="clickRouteInformation()"
                  class="pl-1"
                  x-small
                  >mdi-information-outline
                </v-icon> -->
              </v-col>
            </v-row>
            <v-row align="center" class="ma-0 pa-2 pb-0 pt-0">
              <v-col
                class="ma-0 pa-0 d-flex text-center justify-center"
                cols="12"
              >
                <span class="pl-1 bth font-weight-bold">
                  {{ $t("Evoucher") }} {{ itemName }}
                </span>
              </v-col>
            </v-row>
            <v-row align="center" justify="center" class="ma-0 pa-0 pt-0 pb-2">
              <v-col class="d-flex justify-center pa-0 ma-0" cols="12">
                <div class="rule-2">
                  &#9679;
                  <span v-if="routeInformation && routeInformation.fare_rule.max_trip == 0">
                    {{$t("UnlimitedTrips")}}</span
                  >
                  <span v-else-if="$i18n.locale == 'vi' && routeInformation && routeInformation.fare_rule.max_trip != 0">
                    Tập {{ routeInformation && routeInformation.fare_rule.max_trip }} vé</span
                  >
                  <span v-else>{{ routeInformation && routeInformation.fare_rule.max_trip }} trips</span>
                  &#9679;
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="ma-0 pa-0 d-flex justify-center">
                <p class="currency-multi" v-if="currency && price">
                  {{ Intl.NumberFormat('de-DE', { style: 'currency', currency: currency }).format(price) }} 
                </p>
              </v-col>
            </v-row>
          </v-col>
          <v-col v-else class="pt-0 pb-0" cols="11">
            <v-row align="center" justify="center" class="ma-0 pa-0 pt-1">
              <v-col class="d-flex justify-center pa-0 ma-0" cols="12">
                <div class="route-1">{{ itemName }}
                  <v-icon
                  v-on:click="clickRouteInformation()"
                  class="pl-1"
                  x-small
                  >mdi-information-outline
                </v-icon>
              </div>
              </v-col>
            </v-row>
            <v-row align="baseline" class="ma-0 pb-0 pt-0">
              <v-col class="ma-0 pa-0" cols="5">
                <p class="bth break-word">{{ routeInformation ? routeInformation.origin_code : '' }}</p>
                <p class="place-desc d-flex justify-center text-center break-word">{{ routeInformation ? routeInformation.origin_loc : '' }}</p>
              </v-col>
              <v-col class="mb-2 pa-1 d-flex justify-center" cols="2">
                <img
                  alt=""
                  class="vector m-auto"
                  :src="require('@/assets/buy-ticket/bus-vector.svg')"
                />
              </v-col>
              <v-col class="ma-0 pa-0" cols="5">
                <p class="bth break-word">{{ routeInformation ? routeInformation.destination_code : '' }}</p>
                <p class="place-desc d-flex justify-center text-center break-word">{{ routeInformation ? routeInformation.destination_loc : '' }}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="ma-0 pa-0 d-flex justify-center mb-2">
                <p class="num-135000-v-nd break-word" v-if="currency && price">
                  {{ Intl.NumberFormat('de-DE', { style: 'currency', currency: currency }).format(price) }} 
                </p>
              </v-col>
            </v-row>
          </v-col>
          <img class="divider" :src="require('@/assets/divider-ticket.svg')" />
          <v-col class="d-flex flex-column justify-left col-kanan" cols="4">
            <p class="qty-cart">
              {{$t("Quantity")}} : {{ quantity }}
            </p>
            <p class="num-135000-v-nd-2" v-if="currency && priceTotal">
              {{ Intl.NumberFormat('de-DE', { style: 'currency', currency: currency }).format(priceTotal) }} 
            </p>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import store from "@/store";
export default {
  name: "CardCartNew",
  lastprice: "",
  props: {
    fareID: {
      type: String,
      default: ""
    },
    fareType: {
      type: Number,
      default: 0
    },
    itemName: {
      type: String,
      default: ""
    },
    // orig: { 
    //   type: String, 
    //   default: "" 
    // },
    // origin: { 
    //   type: String, 
    //   default: "" 
    // },
    // dest: { 
    //   type: String, 
    //   default: "" 
    // },
    // destination: {
    //   type: String,
    //   default: "",
    // },
    routeInformation: {
      type: Object,
      default: () => { return null }
    },
    // time: {
    //   type: String,
    //   default: "Single Trip ticket is valid for 24 hours",
    // },
    price: {
      type: Number,
      default: 0
    },
    priceTotal: {
      type: Number,
      default: 0
    },
    currency: {
      type: String,
      default: "VNĐ"
    },
    clickInformation: {
      /*  set handler function after click oke/ yes
       */
      type: Function,
      required: false,
    },
    value: {
      default: 0,
      type: Number,
    },
    min: {
      default: 1,
      type: Number,
    },
    max: {
      default: 0,
      type: Number,
    },
    quantity: {
      default: 0,
      type: Number,
    },
    selected: {
      type: Boolean,
      default: true
    },
    index: {
      type: Number
    },
    customerCartID: {
      type: Number,
    },
    customerID: {
      type: String,
    }
  },
  data() {
    return {
      newValue: 0,
      buy: false,
    };
  },
  computed: {
    select: {
      get() {
        return this.selected
      },
      set(val) {
        this.changeSelected(val, this.index)
      },
    },
    items() {
      return store.state.purchaseticket.cart
    },
    countCart() {
      return store.state.purchaseticket.countCart
    },
    subTotal() {
      let itemTemp = this.items.filter((el) => {
        return el.selected == true;
      });
      let _subtotal = itemTemp.map(
        (itm) => this.convertPriceByCurrency(itm.priceTotal, itm.currencyCode)
      );
      let total;
      if (_subtotal.length > 1) {
        total = _subtotal.reduce((a, b) => a + b);
      } else {
        total = _subtotal;
      }
      return total;
    },
    discountData() {
      return store.state.purchaseticket.discount
    },
  },
  mounted() {
  },
  methods: {
    clickRouteInformation() {
      this.$store.commit("moduleUi/ROUTE_INFORMATION", this.routeInformation);
      this.clickInformation();
    },
    clickBuy() {
      this.buy = true;
      this.$emit("buy", true);
      this.mpplus();
    },
    // changeSelected(val, index) {
    //   store.commit("purchaseticket/CHANGE_SELECTED", { index, val });
    // },
    async changeSelected() {
      let payload = {
        customerCartID: this.customerCartID,
        customerID: this.customerID
      };
      await this.$store
        .dispatch("purchaseticket/selectcart", payload)
        .then((response) => {
          if (response.status) {
            const param = {
              index: this.index,
              val: response.data
            }
            const currentCart = this.items.filter(fx => fx.customerCartIDs[0] == this.customerCartID)[0]
            const updateCart = this.countCart + (currentCart.itemQty * (param.val ? 1 : -1))
            store.commit("purchaseticket/CHANGE_SELECTED", param);
            store.commit("purchaseticket/GETQTYCART_SUCCESS", updateCart);
          } else {
            const param = {
              index: this.index,
              val: response.data
            }
            const currentCart = this.items.filter(fx => fx.customerCartIDs[0] == this.customerCartID)[0]
            const updateCart = this.countCart + (currentCart.itemQty * (param.val ? 1 : -1))
            store.commit("purchaseticket/CHANGE_SELECTED", param);
            store.commit("purchaseticket/GETQTYCART_SUCCESS", updateCart);
          }
        })
        .catch((err) => {
          console.log(err);
        });

      if (this.discountData.data) {
        let discData = this.discountData.data
        payload = {
          ...discData,
          customer_id: store.state.auth.userData.customerID,
          partner_id: store.state.auth.userData.partnerID,
          amount_transaction: this.subTotal[0] || this.subTotal
        }
        if (payload.discount_id != "") {
          await this.$store.dispatch("discount/validation", payload)
          .then(res => {
            if (res.status) {
              const val = {
                name: discData.code,
                value: res.data.discount_price,
                type: discData.type,
                data: discData
              }
              store.commit("purchaseticket/SET_DISCOUNT", val);
            }
          })
          .catch(err => {
            console.log(err)
            const val = {
              value: 0
            }
            store.commit("purchaseticket/SET_DISCOUNT", val);
          })
        }
      }
    },
    convertPriceByCurrency(price, currency) {
      const conversionRates = this.$store.state.purchaseticket.currency ? this.$store.state.purchaseticket.currency.conversionRates : null;
      const priceResult = price * conversionRates[currency];
      return priceResult;
    },
  },

  watch: {
    value: {
      handler: function (newVal) {
        this.newValue = newVal;
      },
    },
  },
  created: function () {
    this.newValue = this.value;
    this.lastprice = this.price;
    // console.log(this.routeInformation)
  },
};
</script>

<style lang="scss" scoped>
.divider {
  border: none;
  width: 2px;
  height: 110px;
}

.v-card.theme--light.v-sheet {
  // position: absolute;
  // border: 0.5px solid #c5c5c5;
  overflow: hidden;
}

.v-application .elevation-1 {
  box-shadow: 0px 1px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
}

.col-kanan {
  height: 100%;
}

.halfcircle {
  background: #ffffff;
  width: 12.1px;
  height: 22.1px;
  position: absolute;
  top: 45%;
  border: 1px solid black;
  overflow: hidden;
  z-index: 5;
}

.cut-out {
  border-radius: 50px;
  width: 100%;
  height: 20%;
  display: block;
  position: absolute;
  top: 40%;
  background: #fdfdfd;
}


.halfcircle-1 {
  left: -97%;
  box-shadow: inset -1px 2px 2px -1px #888;
}

.rule-2 {
  color: rgba(83, 83, 83);
  font-size: 10px;
}

.halfcircle-2 {
  right: -97%;
  box-shadow: inset 1px 1px 1px 1px #888;
}

.qty-cart {
  font-size: 14px;
  margin-bottom: 0;
}

.flex-grid {
  display: flex;
}

.col {
  flex: 1;
}

.crop-kiri {
  margin-right: 0%;
  height: 200px;
}

.crop-kanan {
  margin-left: -10%;
  height: 200px;
}

.rule-1 {
  color: rgba(83, 83, 83);
  // margin-left: 10px;
  // margin-bottom: -6px;
  font-size: 14px;
}

.card-content {
  // width: 90%;
  // border: 1px solid rgba(72, 158, 75, 1);
  margin: 0;
  // border-radius: 10px;
  max-height: 25%;
}

.content {
  margin-bottom: -12px;
}

.currency-multi {
  margin: 0;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 5px;
  color: rgba(242, 153, 0, 1);
}

.component-1 {
  float: left;
  width: 100%;
  position: relative;
}

.btn-buy-hijau {
  width: 100%;
}

.content-ticket {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  color: white;
  padding: 1em;
}

.background-ticket {
  display: block;
  width: 100%;
}

.route-1 {
  // margin-bottom: -10px;
  color: rgba(134, 134, 134, 1);
  font-size: 12px;
  // margin-left: -9%;
  padding-left: 4px;
  padding-right: 4px;
  text-align: center;
  overflow-wrap: anywhere;
}

.middle-row {
  height: 50px;
  margin-top: -0px !important;
}

.flex-wrapper-three {
  margin-top: -10px;
  padding: 0 0 0 0px;
  display: flex;
  align-items: center;
}

.left-row {
  margin-left: 90px;
}

.bth {
  font-size: 15px;
  margin: 0;
  padding: 0;
  color: rgba(72, 158, 75, 1);
  text-align: center;
}

.bth-2 {
  font-size: 18px;
  overflow: hidden;
  margin-left: 10%;
  margin-bottom: 10px;
  padding: 0;
  color: rgba(72, 158, 75, 1);
}

.bth-3 {
  font-size: 15px;
  overflow: hidden;
  // margin-left: 10%;
  margin-bottom: 3px;
  // padding: 0;
  color: rgba(72, 158, 75, 1);
}

.middle-text {
  margin-left: -5% !important;
}

.relative-wrapper-one {
  margin-right: 15px;
  position: relative;
}

.vector {
  margin-top: 0px;
  padding-top: 20px;
  width: 80%;
  margin-left: -50%;
  height: auto;
}

.checkbox-mid {
  height: 100%;
  margin-left: 100%;
}

.group {
  position: absolute;
  left: 55px;
  top: -1px;
  transform: rotate(180deg);
}

.flex-wrapper-four {
  margin-left: 10px;
  margin-bottom: -40px;
  // padding: 0 0 0 21px;
  // display: flex;
  // align-items: flex-start;
}

.bn-thanh {
  // @include roboto-10-light;
  margin-top: -15px;
  font-size: 9px;
  color: rgba(75, 177, 78, 1);
  right: 122px;
}

.place-desc {
  font-size: 10px;
  color: rgba(75, 177, 78, 1);

  margin-top: -3px;
  margin-bottom: 0px;
  padding-bottom: 0;
}

.vector-7 {
  width: 100%;
  margin-bottom: 9px;
}

.flex-wrapper-five {
  padding: 0 0 0 17px;
  display: flex;
  align-items: flex-start;
}

.flex-wrapper-six {
  margin-right: 43px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.route-information {
  // @include roboto-12-medium;
  font-size: 8px;
  color: rgba(94, 215, 153, 1);
  text-decoration: underline;
  margin-top: 0px;
  margin-left: 25%;
  margin-bottom: -2px;
}

.flat-fare-ticket-is-valid-for-24-hours {
  // @include roboto-8-light;
  font-size: 8px;
  margin-left: 25%;
  color: rgba(75, 177, 78, 1);
  font-style: italic;
}

.num-135000-v-nd {
  // @include roboto-14-medium;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 5px;
  // margin-left: -7%;
  color: rgba(242, 153, 0, 1);
}

.num-135000-v-nd-2 {
  // @include roboto-14-medium;
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 5px;
  color: rgba(242, 153, 0, 1);
}

.group-18785 {
  border-radius: 23px;
  height: 31px;
  width: 80px;
  padding: 5px 6px 2px 4px;
  position: relative;
  border: 1px solid rgba(75, 177, 78, 0.35);
}

.class-btn-buy {
  height: 20px;
  // border-radius: 23px;
  // height: 29px;
  // padding: 2px 6px 2px 4px;
  // border: 1px solid rgba(75, 177, 78, 0.35);
  // position: absolute;
  // width: 76px;
  // height: 29px;
  // left: 300px;
  // top: 535px;

  // background: #4BB14E;
  /* Button */

  // box-shadow: 0px 6px 2px -4px rgba(14, 14, 44, 0.1), inset 0px -1px 0px rgba(14, 14, 44, 0.1);
}

.flex-wrapper-one {
  background-color: #ffffff;
  border-radius: 50%;
  padding: 11px 10px 10px 10px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  position: absolute;
  left: 41px;
  top: -1px;
  border: 1px solid rgba(75, 177, 78, 1);
}

.flex-wrapper-two {
  background-color: #ffffff;
  border-radius: 50%;
  padding: 11px 10px 10px 10px;
  display: flex;
  height: 30px;
  width: 30px;
  align-items: center;
  position: absolute;
  left: -10px;
  top: -1px;
  border: 1px solid rgba(75, 177, 78, 1);
}

.hyphen {
  width: 5px;
  height: 2px;
  background-color: rgba(75, 177, 78, 1);
}

.label {
  width: 64px;
  // @include roboto-10-bold;
  color: rgba(75, 177, 78, 1);
  text-align: center;
  position: relative;
}

.btn-plus {
  top: -5px;
  height: 30px;
  width: 30px;
  left: 45px;
}

.btn-minus {
  top: -5px;
  height: 30px;
  width: 30px;
  left: -40px;
}

.icon-plus {
  height: 6px;
  width: 1px;
  left: -1px;
}

.icon-minus {
  height: 6px;
  width: 1px;
  left: -1px;
}

.input-label {
  // left: 4px;
  top: -30px;
}

.onHover {
  cursor: pointer;
}
</style>