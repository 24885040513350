<template>
  <v-row class="mt-12">
    <!-- Jangan dikasih margin, nanti bisa jadi ga fixed navbar nya -->
    <v-col
      class="pb-1 ml-0 mr-0 d-flex justify-center"
      cols="12"
      no-gutters
      v-for="(item, index) in items"
      :key="index"
    >
      <CardCartNew
        :fare-id="item.fareID"
        :fare-type="item.fareType"
        :itemName="item.itemName"
        :price="item.convertedPrice"
        :price-total="item.convertedPriceTotal"
        :currency="item.selectedCurrency"
        :clickInformation="clickRouteInformation"
        :routeInformation="item.routeInformation"
        :quantity="item.itemQty"
        v-model="item.itemQty"
        :selected="item.selected"
        :index="index"
        :customerID="item.customerID"
        :customerCartID="item.customerCartIDs[0]"
      />
    </v-col>
  </v-row>
</template>

<script>
import CardCartNew from "@/components/CardCartNew.vue";
// import { component } from 'vue/types/umd';

export default {
  props: ["items", "clickRouteInformation", "togglebuy", "selected"],
  components: { CardCartNew },
  methods: {
    getSelected(item) {
      return this.selected.filter(fx => item.customerCartIDs[0] === fx)
    }
  },
};
</script>

<style scoped>
/* .col {
} */
</style>