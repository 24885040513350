<template>
  <v-container v-if="userRole.toUpperCase().includes('BLACKLIST')">
    <div>
      <v-row
        justify="center"
        class="null-item"
      >
        <v-col class="d-flex justify-center pb-0" cols="10">
          <v-img
            class="image"
            :src="require('../../assets/bus-homepage.svg')"
          ></v-img>
        </v-col>
        <v-col class="d-flex justify-center py-0 mt-4" cols="12">
          <h2 class="text-center">{{ $t("SuspendWarning") }}</h2>
        </v-col>
      </v-row>
    </div>
  </v-container>
  <v-container v-else fluid class="home">
    <!-- <RouteInformation
      :dialog="notifRoute"
      :ToDest="routeInformation.route_loc"
      :ToOrig="routeInformation.route_loc"
      :destination="routeInformation.destination_loc"
      :origin="routeInformation.original_loc"
      :routeTitle="routeInformation.cart_item_name"
      :orig="routeInformation.orig_loc"
      :dest="routeInformation.dest_loc"
      :titleheadlineCard="routeInformation.route_loc"
      :btnClose="closeRouteInformation"
    /> -->
    <RouteInformation
      v-if="itemRoute && (itemRoute.type==1||itemRoute.type===2)"
      :dialog="notifRoute"
      :routeTitle="itemRoute.route_long_name"
      :keyItem="itemRoute.fare_id"
      :origin="itemRoute.origin_loc"
      :ToOrig=itemRoute.route_loc
      :destination="itemRoute.destination_loc"
      :ToDest=itemRoute.route_loc
      :orig="itemRoute.origin_code"
      :dest="itemRoute.destination_code"
      :expiredNote="itemRoute.expiredNote"
      :validate_close_date="itemRoute.validate_close_date"
      :validate_open_date="itemRoute.validate_open_date"
      :fare-rule="itemRoute.fare_rule"
      :RouteCode="itemRoute.route_short_name"
    />
    <RouteInformationMulti
      v-else-if="itemRoute && itemRoute.type===3"
      :dialog="notifRoute"
      :routeTitle="itemRoute.name"
      :keyItem="itemRoute.fare_id"
      :expiredNote="itemRoute.expiredNote"
      :fare-rule="itemRoute.fare_rule"
      :price="itemRoute.price"
      :currency-code="itemRoute.currency_code"
      :route-loc="itemRoute.route_loc"
    />

    <notification-popup
      :dialog="notifFailed"
      :contentMsg="$t('LimitForEachTicket')"
      :headerMsg="$t('Failed')"
      :nameBtnNo="$t('labelNo')"
      :nameBtnYes="$t('Close')"
      disableBtnNo="true"
      colorIcon="danger"
      typeModal="transaction"
      Icon="mdi-close-circle"
      :onHandlerYes="buttonOkeFailed"
    />

    <v-app-bar fixed class="mt-12" elevation="0" color="white">
      <v-toolbar-title>
        <v-checkbox
          v-model="allSelected"
          color="primary"
          :label="$t('SelectAll')"
          :indeterminate="indeterminate"
        ></v-checkbox>
      </v-toolbar-title>

      <v-spacer></v-spacer>
      
      <v-btn class="mb-7" icon v-on:click="deleteItem">
        <v-icon :color="$partnerACL.getButtonColor()">mdi-trash-can</v-icon>
      </v-btn>
    </v-app-bar>

    <v-container>
      <v-row v-if="items.length === 0 && isDataLoaded" justify="center" class="null-item">
        <v-col class="d-flex justify-center pb-0" cols="10">
          <v-img
            class="image"
            :src="require('@/assets/icon/no-ticket.svg')"
          ></v-img>
        </v-col>
        <v-col class="d-flex justify-center py-0" cols="12">
          <h2 class="notfication">
            {{ $t("NoTicket") }}
            <!-- You do not have a ticket in the cart -->
          </h2>
        </v-col>
        <v-col class="d-flex justify-center mt-3">
          <!-- <v-btn
            rounded
            color="primary"
            class="capitalize body-1"
            @click="continueToBuy()"
          >
            {{ $t("PurchaseTicket") }}
          </v-btn> -->
          <!-- Continue to buy -->
          <ac-button 
            :active="$partnerACL.isShowButton('purchase')"
            :name="$t('PurchaseTicket')" 
            :on-click-handler="continueToBuy"
          />
        </v-col>
      </v-row>
      <ListofCart v-else-if="isDataLoaded" :items="items" :selected="itemSelected" :clickRouteInformation="clickRouteInformation" />

    </v-container>

    <v-footer fixed app width="auto" color="white" elevation="3">
      <!-- <v-container class="mt-0 pt-0 mb-0 pb-0">
        <v-row class="ma-2 pa-0">
          <v-col class="ma-0 pa-0 d-flex justify-start" align-self="center">
            <h2 class="labelgrandtotal">{{ $t("Total") }}</h2>
          </v-col>
          <v-col class="ma-0 pa-0 d-flex justify-end" align-self="center">
            <h2 class="grandtotal" width="auto">
              {{ Intl.NumberFormat('de-DE', { style: 'currency', currency: selectedCurrency }).format(totalAmount) }} 
            </h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="mt-0 pt-0"
            cols="12"
            align-self="center"
          >
            <ac-button 
              :active="$partnerACL.isShowButton('checkout')"
              :name="$t('CheckOut')" 
              :on-click-handler="checkOut"
              :is-disabled="totalAmount == 0"
              width="100%"
            />
          </v-col>
        </v-row>
      </v-container> -->

      <footer-checkout
        position="checkout"
      />
    </v-footer>
    <!-- <p>{{selected}}</p> -->
  </v-container>
</template>

<script>
import store from "@/store";
// import MinusPlusButtonCart from '@/components/MinPlusButtonCart.vue'
import RouteInformation from "@/components/RouteInformation.vue";
import RouteInformationMulti from "@/components/RouteInformationMulti.vue";
import ListofCart from "@/components/ListofCart.vue";
import NotificationPopup from "@/components/NotificationPopup.vue";
import AcButton from "@/components/AcButton.vue";
import FooterCheckout from "@/components/FooterCheckout.vue";
import moment from 'moment';

export default {
  name: "Cart",
  components: {
    // MinusPlusButtonCart,
    RouteInformation,
    RouteInformationMulti,
    ListofCart,
    NotificationPopup,
    AcButton,
    FooterCheckout
  },
  data() {
    return {
      notifRoute: false,
      timestamp: "",
      selected: [],

      notifSuccess: false,
      notifFailed: false,
      total: 0,
      routeInformation: {},
      psg_detail: store.state.auth.userData.user_name,
      isDataLoaded: false
    };
  },
  computed: {
    selectedCurrency() {
      return this.$store.state.purchaseticket.currency ? this.$store.state.purchaseticket.currency.code : ""
    },
    itemRoute() {
      return this.$store.state.moduleUi.routeInformation;
    },
    items() {
      return store.state.purchaseticket.cart
    },
    indeterminate() {
      let itemTemp = this.items.filter((el) => {
        return el.selected == true;
      });
      if (itemTemp.length < this.items.length && itemTemp.length !== 0) {
        return true;
      } else return false;
    },
    allSelected: {
      get() {
        let itemTemp = this.items.filter((el) => {
          return el.selected == true;
        });
        if (itemTemp.length == this.items.length) {
          return true;
        } else return false;
      },
      set(val) {
        this.selectAll(val)
      },
    },
    totalAmount() {
      let itemTemp = this.items.filter((el) => {
        return el.selected == true;
      });
      var subtotal = itemTemp.map(
        (itm) => this.convertPriceByCurrency(itm.priceTotal, itm.currencyCode)
      );
      var total;
      if (subtotal.length > 1) {
        total = subtotal.reduce((a, b) => a + b);
      } else {
        total = subtotal;
      }
      return total;
    },
    customerID() {
      return store.state.auth.userData.customerID;
    },
    userData() {
      return this.$store.state.auth.userData;
    },
    itemSelected() {
      return store.state.purchaseticket.cartSelected
    },
    userRole() {
      return this.$store.state.auth.user_role;
    }
  },
  watch: {
    // items: {
    //   // handler: function(){
    //   //   if(this.items.length==0){
    //   //    this.$router.go(-1)
    //   //   }
    //   // },
    //   // deep:true
    // },
    itemRoute() {
      if (this.itemRoute == "") {
        this.notifRoute = false;
      } else {
        this.notifRoute = true;
      }
    },
  },
  async created() {
    const payloadCurrency = {
      currencyCode: localStorage.getItem('currency') || "IDR",
    };
    await this.$store.dispatch("purchaseticket/getCurrencyConversionRate", payloadCurrency)
    await this.refreshItem();
    this.isDataLoaded = true;
  },
  mounted() {
  },
  methods: {
    convertPriceByCurrency(price, currency) {
      const conversionRates = this.$store.state.purchaseticket.currency ? this.$store.state.purchaseticket.currency.conversionRates : null;
      const priceResult = price * conversionRates[currency];
      return priceResult;
    },
    getExpiredNote(fareRule) {
      return fareRule.expired_on_same_day ? 'ExpiredOnSameDay' :
        fareRule.valid_days != null && !fareRule.expired_on_same_day ? 'ExpiredAfterPurchase' :
          fareRule.expired_after_usage ? 'ExpiredAfterUsage' :
            fareRule.validate_close_date == null ? 'Unlimited' :
              'ValidationPeriod'
    },
    clickRouteInformation(itemRoute) {
      this.routeInformation = itemRoute;
      this.notifRoute = true;
    },
    closeRouteInformation() {
      this.notifRoute = false;
    },
    async refreshItem() {
      // const payload = {
      //   customerID: this.customerID,
      // };
      // this.$store.dispatch("purchaseticket/getCart", payload).catch((err) => {
      //   console.log(err);
      // });
      const payload = {
        customerID: this.customerID,
      };
      await this.$store.dispatch("purchaseticket/getCart", payload)
        .then((res) => {
          const cartitems = res.data.map(item => {
            return item.fareType === 3
              ? {
                ...item,
                // selected: true,
                convertedPrice: this.convertPriceByCurrency(item.price, item.currencyCode),
                convertedPriceTotal: this.convertPriceByCurrency(item.priceTotal, item.currencyCode),
                selectedCurrency: this.selectedCurrency,
                routeInformation: item.routeInformation.length > 0 ?
                  {
                    ...item.routeInformation[0],
                    expiredNote: this.getExpiredNote(item.routeInformation[0].fare_rule),
                  } : null
              }
              : {
                ...item,
                // selected: true,
                convertedPrice: this.convertPriceByCurrency(item.price, item.currencyCode),
                convertedPriceTotal: this.convertPriceByCurrency(item.priceTotal, item.currencyCode),
                selectedCurrency: this.selectedCurrency,
                routeInformation: item.routeInformation.length > 0 ?
                  {
                    ...item.routeInformation[0],
                    origin_loc: item.routeInformation[0].route_loc.length > 0 ? item.routeInformation[0].route_loc[0].trip_route[0] : "",
                    origin_code: item.routeInformation[0].route_loc.length > 0 ? item.routeInformation[0].route_loc[0].trip_route_by_code[0] : "",
                    destination_loc: item.routeInformation[0].route_loc.length > 0 ? item.routeInformation[0].route_loc[item.routeInformation[0].route_loc.length - 1].trip_route[item.routeInformation[0].route_loc[item.routeInformation[0].route_loc.length - 1].trip_route.length - 1] : "",
                    destination_code: item.routeInformation[0].route_loc.length > 0 ? item.routeInformation[0].route_loc[item.routeInformation[0].route_loc.length - 1].trip_route_by_code[item.routeInformation[0].route_loc[item.routeInformation[0].route_loc.length - 1].trip_route_by_code.length - 1] : "",
                    expiredNote: this.getExpiredNote(item.routeInformation[0].fare_rule),
                    validate_open_date: item.routeInformation[0].fare_rule.validate_open_date ? moment.utc(item.routeInformation[0].fare_rule.validate_open_date).format("DD-MM-YYYY") : "",
                    validate_close_date: item.routeInformation[0].fare_rule.validate_close_date ? moment.utc(item.routeInformation[0].fare_rule.validate_close_date).format("DD-MM-YYYY") : ""
                  } : null
              }
          })
          
          let filterSelect = cartitems.filter(fx => fx.selected)
          let selectedCount = filterSelect.length > 1 ? filterSelect
            .reduce((prev, next) => {
              return prev + next.itemQty
            }, 0) : filterSelect.length == 0 ? 0 : filterSelect[0].itemQty
            
          this.$store.commit("purchaseticket/SET_CART", cartitems)
          this.$store.commit("purchaseticket/GETQTYCART_SUCCESS", selectedCount)
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteItem() {
      if (this.allSelected) {
        this.delAll();
      } else {
        const selected_item = store.state.purchaseticket.cart.filter((el) => {
          return el.selected == true;
        });

        selected_item.forEach((element) => {
          this.delItem(element);
        });
      }
    },
    delItem(item) {
      const payload = {
        customerID: this.customerID,
        customerCartID: item.customerCartID,
        fareID: item.fareID,
      };
      this.$store
        .dispatch("purchaseticket/delItem", payload)
        .then((response) => {
          // this.notifSuccess = true
          // this.item.item_qty = 0
          this.refreshItem();
          if (response.status) console.log("success");
        })
        .catch((err) => {
          // this.notifFailed = true;
          console.log(err);
        });
    },
    delAll() {
      const payload = {
        customerID: this.customerID,
        customerCartID: 0,
        fareID: "",
      };
      this.$store
        .dispatch("purchaseticket/delItem", payload)
        .then((response) => {
          // this.notifSuccess = true
          // this.item.item_qty = 0
          this.refreshItem();
          if (response.status) console.log("success");
        })
        .catch((err) => {
          // this.notifFailed = true;
          console.log(err);
        });
    },
    updateItem(item, item_qty) {
      const payload = {
        customerID: this.customerID,
        cart_fare_uuid: item.cart_fare_uuid,
        item_qty: "" + item_qty + "",
      };
      this.$store
        .dispatch("purchaseticket/updateItem", payload)
        .then((response) => {
          this.items = [];
          for (let data of response.data) {
            this.items.push(data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // checkCart(){
    //   let selected_item = store.state.purchaseticket.cart.filter((el) => {
    //     return el.selected == true && el.item_qty > 15;
    //   });

    //     if (selected_item.length > 0) {
    //       this.notifFailed = true;
    //     }
    //   console.log("allSelected", selected_item)
    // },

    buttonOkeFailed() {
      this.notifFailed = false;
    },
    checkOut() {
      // let selected_item = store.state.purchaseticket.cart.filter((el) => {
      //   return el.selected == true && el.item_qty > 15;
      // });
      // console.log("selected",selected);
      // console.log("selected_item",selected_item);
      // const today = new Date();
      // const date =
      //   today.getFullYear() +
      //   "" +
      //   (today.getMonth() + 1) +
      //   "" +
      //   today.getDate();
      // const time =
      //   today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
      // const dateTime = date + "" + time;
      // this.timestamp = dateTime;
      // const payload = {
      //   inv_no: "ACASIA-" + this.timestamp + "" + this.customerID + "",
      //   inv_psg_id: this.customerID,
      //   inv_psg_detail: this.psg_detail,
      //   inv_amount: "" + this.total + "",
      //   inv_detail: selected,
      // };
      // console.log("payload",payload)

      const checkoutButton = this.$partnerACL.getMetaButton('checkout');
      if (checkoutButton) {
        if (checkoutButton.menu_type == "wsc_menu") {
          this.$router.replace({ path: checkoutButton.redirect_path });
        } else {
          window.location = checkoutButton.redirect_path;
        }
      } else {
        this.$router.push({ name: "Checkout" });
      }


      // if (selected_item.length > 0) {
      //   this.notifFailed = true;
      // } else {
      //   const today = new Date();
      //   const date =
      //     today.getFullYear() +
      //     "" +
      //     (today.getMonth() + 1) +
      //     "" +
      //     today.getDate();
      //   const time =
      //     today.getHours() + "" + today.getMinutes() + "" + today.getSeconds();
      //   const dateTime = date + "" + time;
      //   this.timestamp = dateTime;
      //   const payload = {
      //     inv_no: "ACASIA-" + this.timestamp + "" + this.customerID + "",
      //     inv_psg_id: this.customerID,
      //     inv_psg_detail: this.psg_detail,
      //     inv_amount: "" + this.total + "",
      //     inv_detail: selected,
      //   };

      //   const checkoutButton = this.$partnerACL.getMetaButton('checkout');
      //   if(checkoutButton){
      //     if(checkoutButton.menu_type == "wsc_menu"){
      //       this.$router.replace({ path: checkoutButton.redirect_path, params: { data: payload } });
      //     }else {
      //       window.location = checkoutButton.redirect_path;
      //     }
      //   }else {
      //     this.$router.push({ name: "Checkout", params: { data: payload } });
      //   }
      // }
    },
    buttonOke() {
      this.notifSuccess = false;
      this.item.item_qty = 0;
      if (this.notifSuccess == false) this.$router.push({ name: "Cart" });
    },
    async selectAll(val) {
      // store.commit("purchaseticket/SELECT_ALL", val);
      let payload = {
        customerID: this.customerID,
        status: val
      };
      await this.$store
        .dispatch("purchaseticket/selectallcart", payload)
        .then((response) => {
          if (response.status) {
            store.commit("purchaseticket/SELECT_ALL", val);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    continueToBuy() {
      this.$router.replace({ name: "Ticket" });
    },
    select: function () {
      this.allSelected = false;
      var total = 0; //set a variable that holds our total
      var data = this.selected; //reference the element in the "JSON" aka object literal we want
      var i = 0;
      for (i = 0; i < data.length; i++) {
        //loop through the array
        total += data[i].cart_price_after_discount * data[i].item_qty; //Do the math!
      }
      this.total = total;
    },
  },
};
</script>
<style lang="scss" scoped>
.rounded-card {
  border-radius: 15px !important;
  background: linear-gradient(180deg, #ffffff 0%, #ffe9fc 100%);
}

.footer {
  margin-top: 25px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  background: #ffffff;
  mix-blend-mode: normal;
  /* Field */

  box-shadow: 0px 1px 10px 0px;
}

.labelgrandtotal {
  // font-size: 20px;
  // font-weight: 600;
  align-self: center;
  color: rgba(75, 177, 78, 1);
}

.grandtotal {
  // font-size: 25px;
  // font-weight: 800;
  color: rgba(242, 153, 0, 1);
}

.btn-cart {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: capitalize;
}

.notification {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

.null-item {
  position: absolute;
  top: 46%;
  right: 50%;
  transform: translate(46%, -50%);
}

// card
/* If the screen size is 673px or more, set the font-size of <div> to 80px */
// @media only screen and (max-width: 600px) {
.component-1 {
  padding: 10px 14px 10px 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-image: url("~@/assets/buy-ticket/bg-card.svg");
}

.cardCart {
  background-color: #f5fdf7;
  display: flex;
  flex-wrap: nowrap;
}

.route-1 {
  color: rgba(134, 134, 134, 1);
  margin-bottom: -10px;
  margin-left: 150px;
}

.flex-wrapper-three {
  margin-top: -10px;
  padding: 0 0 0 25px;
  display: flex;
  align-items: center;
}

.bth {
  font-size: 24px;
  color: rgba(72, 158, 75, 1);

  &:not(:last-of-type) {
    margin-right: 30px;
  }
}

.relative-wrapper-one {
  margin-right: 31px;
  position: relative;
}

.vector {
  position: relative;
}

.group {
  position: absolute;
  left: 55px;
  top: -1px;
  transform: rotate(180deg);
}

.flex-wrapper-four {
  margin-left: 10px;
  margin-bottom: -40px;
}

.bn-thanh {
  margin-top: -15px;
  margin-left: 15px;
  font-size: 10px;
  color: rgba(75, 177, 78, 1);
  right: 122px;

  &:not(:last-of-type) {
    margin-right: 135px;
  }
}

.bn-bbc {
  margin-top: -30px;
  font-size: 10px;
  color: rgba(75, 177, 78, 1);
  margin-right: -100px;
  text-align: right;
}

.vector-7 {
  margin-top: 35px;
  margin-left: 9px;
  margin-bottom: 9px;
}

.flex-wrapper-five {
  padding: 0 0 0 17px;
  display: flex;
  align-items: flex-start;
}

.flex-wrapper-six {
  margin-right: 43px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.route-information {
  // @include roboto-12-medium;
  font-size: 12px;
  color: rgba(94, 215, 153, 1);
  text-decoration: underline;
  margin-top: -2px;
  margin-bottom: -2px;
}

.flat-fare-ticket-is-valid-for-24-hours {
  // @include roboto-8-light;
  font-size: 8px;
  color: rgba(75, 177, 78, 1);
  font-style: italic;
}

.num-135000-v-nd {
  // @include roboto-14-medium;
  font-size: 12px;
  color: rgba(242, 153, 0, 1);
  margin-top: 4px;
  margin-right: 9px;
}

.group-18785 {
  border-radius: 23px;
  height: 29px;
  width: 80px;
  padding: 2px 6px 2px 4px;
  position: relative;
  border: 1px solid rgba(75, 177, 78, 0.35);
}

.flex-wrapper-one {
  background-color: #ffffff;
  border-radius: 50%;
  padding: 11px 10px 10px 10px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  position: absolute;
  left: 41px;
  top: -1px;
  border: 1px solid rgba(75, 177, 78, 1);
}

.flex-wrapper-two {
  background-color: #ffffff;
  border-radius: 50%;
  padding: 11px 10px 10px 10px;
  display: flex;
  height: 30px;
  width: 30px;
  align-items: center;
  position: absolute;
  left: -10px;
  top: -1px;
  border: 1px solid rgba(75, 177, 78, 1);
}

.hyphen {
  width: 5px;
  height: 2px;
  background-color: rgba(75, 177, 78, 1);
}

.label {
  width: 64px;
  // @include roboto-10-bold;
  color: rgba(75, 177, 78, 1);
  text-align: center;
  position: relative;
}

.btn-plus {
  top: -5px;
  height: 30px;
  width: 30px;
  left: 45px;
}

.btn-minus {
  top: -5px;
  height: 30px;
  width: 30px;
  left: -40px;
}

.icon-plus {
  height: 6px;
  width: 1px;
  left: -1px;
}

.icon-minus {
  height: 6px;
  width: 1px;
  left: -1px;
}

.input-label {
  // left: 4px;
  top: -27px;
}

.onHover {
  cursor: pointer;
}

.delete-card {
  padding-left: 10px;
}

// }
// end cart
</style>

